/* Button.module.css */

.container {
    position: relative;
    display: inline-block;
    overflow: visible;
    padding: 2rem;
}

.button {
    position: relative;
    background-color: #0e0601;
    color: antiquewhite;
    padding: 10px 20px;
    border: 2px black solid;
    border-radius: 13px;
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s ease;
    text-decoration: none;
    outline: none;
}

.button:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background-image: conic-gradient(#d31313, #b1220b, #db4141, #ec0d0d, #fe3434);
    filter: blur(10px);
    z-index: -1;
    transition: filter .1s ease-in-out;
}

.button:hover {
    background-color: #1a1303;
}

.button:hover:before {
    filter: blur(20px);
}