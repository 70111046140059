/* LandingPage.module.css */

.landingContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.button {
    z-index: 99;
}

body {
    background-color: #0e0601;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: qfont, sans-serif;
    font-size: x-large;
}

/* Header styles */
.header {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    color: antiquewhite;
    line-height: 0.6;
    margin-top: 0;
    margin-bottom: 0;
}

/* Footer styles */
.footer {
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    color: antiquewhite;
}

.blurb {
    font-size: large;
}