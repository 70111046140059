/* SwipeImage.module.css */

.imageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 70vh;
}

.image {
    position: absolute;
    height: 70%;
}