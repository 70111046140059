.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: #111;
    color: #fff;
    min-height: 100vh;
}

.header {
    text-align: center;
    margin-bottom: 2rem;
}

.quoteForm {
    width: 100%;
    max-width: 500px;
    background: #222;
    padding: 1.5rem;
    border-radius: 8px;
}

.formGroup {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.formGroup label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.formGroup input,
.formGroup textarea {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #444;
    background: #333;
    color: #fff;
}

.checkGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.checkGroup label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.submitBtn {
    width: 100%;
    padding: 0.75rem;
    background: #c00;
    color: #fff;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
}

.submitBtn:hover {
    background: #e60000;
}

.successMsg {
    margin-top: 1rem;
    color: #0f0;
    font-weight: bold;
    text-align: center;
}

.footer {
    margin-top: 2rem;
    text-align: center;
}