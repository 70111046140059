/******** Page Container ********/
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111;
    /* Dark background */
    color: #fff;
    /* Light text */
    min-height: 100vh;
    padding: 2rem;
}

/******** Header ********/
.header {
    text-align: center;
    margin-bottom: 2rem;
}

.header h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.header p {
    margin: 0.5rem 0;
    font-size: 1rem;
    line-height: 1.5;
}

/******** Locations Section ********/
.locationsSection {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 1rem 0;
}

.locationsSection h2 {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
}

.locationsSection p {
    margin-bottom: 2rem;
    font-size: 1rem;
}

/* The container for the 3 location cards side-by-side */
.locationsContainer {
    display: flex;
    flex-wrap: wrap;
    /* wrap on smaller screens */
    justify-content: center;
    /* center them horizontally */
    gap: 2rem;
    /* space between cards */
}

/******** Individual Location Card ********/
.locationCard {
    background: #222;
    border-radius: 6px;
    padding: 1rem;
    width: 300px;
    /* Adjust as desired */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    text-align: left;
    /* Left-align the content inside each card */
}

/* Force iframes to occupy the full card width */
.locationCard iframe {
    display: block;
    width: 100%;
    height: 200px;
    /* or auto, but you used 200 in the JSX */
    margin-bottom: 1rem;
}

.locationCard h3 {
    margin-top: 0;
    margin-bottom: 0.75rem;
    font-size: 1.2rem;
}

.locationCard p {
    line-height: 1.4;
    margin-top: 0.5rem;
    font-size: 0.95rem;
}

/* Make phone links red and remove default underline on link */
.locationCard a {
    color: #ff4444;
    text-decoration: none;
}

.locationCard a:hover {
    text-decoration: underline;
}

/* This class for the "Store:" and "Office Phone:" labels */
.redLabel {
    color: #ff4444;
    font-weight: bold;
}

/******** Footer ********/
.footer {
    margin-top: auto;
    text-align: center;
    font-size: 0.9rem;
    opacity: 0.8;
    margin-top: 2rem;
}